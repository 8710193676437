.App {
  text-align: center;
  width: 100vw;
}

.App-logo {
  /* height: 40vmin; */
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.slide {
  background: none !important;
}

.fullheight {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.how_dare_you_wrapper {
  height: 100vh;
  background-repeat: no-repeat, repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  padding-bottom: -10vh;
}

.white {
  font-size: 24px;
  color: #fff;
}

/* button */

button {
  margin-top: 20px !important;
  display: inline-block;
  background-color: #1a5276;
  border-radius: 10px;
  border: 4px double #cccccc;
  color: #eeeeee;
  text-align: center;
  font-size: 28px;
  padding: 20px;
  width: 200px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}
button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}
button span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}
button:hover {
  background-color: #2e86c1;
}
button:hover span {
  padding-right: 25px;
}
button:hover span:after {
  opacity: 1;
  right: 0;
}

.wrapper_form_element {
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    background-color: blue !important;
  }
}

.copy {
  color: blue;
  text-decoration: none;
  cursor: pointer;
}

.copy:hover {
  text-decoration: underline;
}

#fitin {
  width: 321px;
  height: 115px;
  overflow: hidden;
  font-size: 1em;
}

#loading {
  height: 100vh;
  width: 100vw;
  position: absolute;
  z-index: 100;
  background-color: white;
}
